const React = require( "react" );

const { ApplicationProvider } = require( "./src/providers/ApplicationProvider.tsx" );

// Wraps every page in a component
exports.wrapPageElement = ( { element, props } ) => {

    let _wq = window._wq || [];

    return <ApplicationProvider { ...props } _wq={ _wq }>{ element }</ApplicationProvider>
}