import React, { useEffect, useState } from 'react';

import TimesIcon from '../components/icons/TimesIcon';

import { stateHandler } from '../helpers/state';

import { graphql, useStaticQuery } from 'gatsby';

const defaultState = {
    videos: [],
    modalOpen : false,
    globalText : null
}

const ApplicationContext : any = React.createContext( defaultState );

const eventHandlers = {

    setModalOpen: ( __state : any, action : any ) => {

        __state.modalOpen = action.value;
    },

    playVideo: ( __state : any, action : any ) => {

        __state.modalOpen = true;

        __state.videos = [ action.value ];
    },

    setGlobalText: ( __state : any, action : any ) => {

        __state.globalText = action.value;
    },
}

const reducer = ( state : any, action : any ) => stateHandler( state, action, eventHandlers );

const ApplicationProvider = ( { children, _wq } : any ) => {

    const __wistia_q : any = _wq || [];

    const [ ready, setReady ] : any = useState();

    const [ state, dispatch ] = React.useReducer(  reducer , defaultState );

    const [ video, setVideo ] : any = useState();

    useEffect( () => {

        setReady( false )

        if( state.videos && state.videos.length > 0 ){
            
            __wistia_q.push( { id: state.videos[ 0 ], onReady: function( video : any ) {

                setReady( true );

                setVideo( video );

                video.pause();

                setTimeout( () => video.play(), 1000 )
            } } );
        }

    }, [ state.videos ] )

    useEffect( () => {

        if( !state.modalOpen ){

            if( video ){

                setReady( false );

                video.pause();
            }
        }

    }, [ state.modalOpen ] )

    const data = useStaticQuery( graphql`
        query {
            allStrapiGlobalText {
                nodes{
                    copyright
                    footer_caption {
                        data {
                            footer_caption
                        }
                    }
                    find_a_therapist_uri
                    find_a_psychiatrist_uri
                }
            }
        }
    `)

    useEffect( () => {

        let global_text = data.allStrapiGlobalText.nodes[ 0 ] || null;

        if( global_text ) 

            dispatch( { type: 'setGlobalText', value : { ...global_text } } )
    }, [] )

    return <ApplicationContext.Provider value={ [ state, dispatch ] }>
        <div className='cover bg-color-white'>
            { children }
        </div>
        <div className='pos-fixed tl-0 cover p-10 align-items-center d-flex' style={ { zIndex: state.modalOpen ? 100 : -100, backgroundColor: state.modalOpen ? '#ffffffdd' : '#ffffff00', transition: 'background-color 1s .5s' } }>
            <div className='w-100 pos-rel mw-1200 mx-auto' style={ { transform: ready ? 'scale(1)' : 'scale(.8)', opacity: ready ? 1 : 0, transition: 'opacity .9s .4s, transform .4s .4s ease-out' } }>
                <button onClick={ () => dispatch( { type: 'setModalOpen', value: false } ) } style={ { backgroundColor: 'transparent', transform: 'translate(90%,-80%)' } } className='pointer pos-abs tr-0 text-color-lake-1 h3 text-color-hover-black'>
                    <TimesIcon />
                </button>
                <div className='w-100 overflow-hidden' style={ { boxShadow: '0px 0px 10px #00000044', borderRadius: 15 } }>
                    { state.videos && state.videos.length > 0 && state.videos.map( ( videoId : string ) => (
                        <div key={ `video-${ videoId }` } className={ `cover wistia_embed wistia_async_${ videoId } seo=false videoFoam=true` } />
                    ) ) }
                </div>
            </div>
        </div>
    </ApplicationContext.Provider>
}

export { ApplicationProvider, ApplicationContext }