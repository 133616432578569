export const stateHandler = ( state : any, action : any, handlers : any ) => {

    let _state = { ...state }

    if( handlers[ action.type ] ){

        handlers[ action.type ]( _state, action );

        return _state;

    } else {

        throw new Error( `${ action.type }, handler not found` );
    }
}